.homepage-layered-section {
  justify-content: space-between;
  padding-left: 30px;
  @media only screen and (max-width: 1189px) {
    padding-left: 0px;
  }
  .all-details-container {
    height: 198vh;
    @media only screen and (max-width: 1898px) {
      height: 218vh;
    }
    @media only screen and (max-width: 1189px) {
      height: 260vh;
    }
    &.showed {
      @media only screen and (max-width: 1189px) {
        height: 180vh;
      }
    }
    display: flex;
    position: relative;
    justify-content: space-between;
    .all-visuals {
      position: sticky;
      top: 0px;
      right: 0;
      z-index: -1;
      @media only screen and (max-width: 1189px) {
        top: 46%;
      }
      &.showed {
        @media only screen and (max-width: 767px) {
          position: relative;
        }
      }
      overflow: hidden;
      svg {
        height: 100vh;
        @media only screen and (max-width: 1898px) {
          height: 120vh;
        }
        @media only screen and (max-width: 1576px) {
          height: 100vh;
        }
        @media only screen and (max-width: 1189px) {
          height: 60vh;
          transform: translate(-50%, 0%);
        }

        max-width: 100%;
        margin-left: 60%;
        transform: translateX(-50%);
        scale: 1.2;
        position: relative;
        z-index: 3;
      }
      .grid {
        position: absolute;
        left: 0px;
        right: 0px;
        z-index: 1;

        @media only screen and (max-width: 1189px) {
          bottom: 0;
        }
        img {
          height: 100vh;
          @media only screen and (max-width: 1898px) {
            height: 120vh;
          }

          @media only screen and (max-width: 1189px) {
            height: 60vh;
          }
          width: 100%;
          object-fit: cover;
          object-position: top;
          opacity: 0.5;
          scale: 1.1;
        }
      }
    }
    &.change {
      .all-content {
        // padding-top: 0;
     
      }
    }
    .all-content {
      position: sticky;
      top: 140px;
      padding-left: 0px;
      
      @media only screen and (max-width: 1189px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      &.showed {
        top: 0;
        padding-top: 140px;
      }
      .element {
        color: white;

        h3 {
          margin-bottom: 6px;
        }
        p {
          font-weight: 300;
          letter-spacing: 0.6px;
          line-height: 22px;
          margin-bottom: 10px;
          position: relative;
          z-index: 5;
          span {
            letter-spacing: 0.33em;
          }
          &:last-of-type {
            margin-bottom: 34px;
          }
        }
        &.element-1 {
          padding-top: 100px;
          @media only screen and (max-width: 1291px) {
            padding-top: 0;
          }

          h3 {
            color: #217FCF;
          }
        }
        &.element-2 {
          h3 {
            color: #FCD24B;
          }
        }
        &.element-3 {
          h3 {
            color: #40E5AA;
          }
        }
        &.element-4 {
          margin-bottom: 50px;
          &.change {
            position: sticky;
            left: 0;
            right: 0;
            top: 200px;
            @media only screen and (max-width: 1189px) {
              z-index: -1;
            }
          }
          h3 {
            color: $primary_color;
          }
        }
      }
    }
  }
  .right {
    overflow: hidden;
    .path {
      &.path-1 {
      }
      &.path-2 {
        max-height: 58%;
      }
      &.path-3 {
        max-height: 78%;
      }
      img {
        z-index: 1;
        &.truck {
          &.second {
            top: unset;
            bottom: 30%;
            transform: translate(0px) rotate(0deg);
            transition: 0.8s;
            &.turn {
              transition: 0.8s;
              bottom: 28%;
              transform: translate(0px, 30px) rotate(-4deg);
            }
          }
        }
        &.van {
          bottom: unset;
          top: 30%;
          &.second {
            top: unset;
            bottom: 15%;
            transition: 0.8s;
            &.turn {
              transition: 0.8s;
              bottom: 2%;
            }
          }
          &.third {
            top: unset;
            transition: 0.8s;
            bottom: -67%;
            left: -25%;
          }
        }
        &.car {
          &.second {
            top: unset;
            bottom: 25%;
            transition: 0.8s;
            &.turn {
              transition: 0.8s;
              bottom: 15%;
            }
          }
          &.third {
            top: unset;
            transition: 0.8s;
            bottom: 0%;
            transform: translate(0px) rotate(0deg);
            &.turn {
              transition: 0.8s;
              bottom: -4%;
              transform: translate(-25px) rotate(10deg);
            }
          }
          &.fourth {
            top: unset;
            transition: 0.8s;
            bottom: -24%;
            left: -12%;
          }
        }
      }
    }
    .barrier {
      position: absolute;
      bottom: -5px;
      right: 0;
      left: 0;
      margin-left: 5px;
      img {
        width: 100%;
      }
    }
  }
}

.data-fusion-mobile-image {
  background-color: black;
  img {
    max-width: 100%;
  }
}

.layered-request-demo {
  @media only screen and (max-width: 2000px) {
    width: 1200px;
  }
  @media only screen and (max-width: 1600px) {
    width: 1000px;
  }
  @media only screen and (max-width: 1200px) {
    width: 900px;
  }
  @media only screen and (max-width: 800px) {
    width: 700px;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
}
