.paths.right {
    position: relative;
    .path {
      position: absolute;
      border-left: 1px solid #383838;
      top: 0;
      bottom: 0;
      width: 33%;
      display: flex;
      justify-content: center;
      &.path-1 {
        right: 66%;
      }
      &.path-2 {
        right: 33%;
      }
      &.path-3 {
        right: 0%;
        border-right: 1px solid #383838;
        top: 0;
        bottom: 0;
      }
      img {
        &.truck {
          position: absolute;
          top: 5%;
        }
        &.van {
          position: absolute;
          bottom: 20%;
        }
        &.car {
          position: absolute;
          top: 20%;
        }
      }
    }
    .gif {
      width: 100%;
    }
  }