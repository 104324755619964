.homepage-policy-legislative-frameworks {
    background-color: white;
    color: black;
    justify-content: space-between;
    overflow: hidden;
    padding-left: 30px;
    @media only screen and (max-width: 767px) {
      padding-left: 0px;
    }
    .left {
      padding-top: 25px;
      padding-bottom: 30px;
      padding-left: 0px;
      @media only screen and (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      .header {
        margin-bottom: 0px;
        h1 {
          color: black;
          line-height: 60px;
          font-size: 55px;
          padding-left: 0;
          padding-right: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 40px;
            line-height: 40px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 28px;
          }
        }
      }
      .details {
        .content {
          min-height: 32vh;
          @media only screen and (max-width: 767px) {
            min-height: unset;
            margin-bottom: 40px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 15px;
            line-height: 20px;
          }
          a {
            color: $primary_color;
            text-decoration: underline;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            &.expander,
            &.collapser {
              font-weight: 700;
              text-decoration: none;
            }
            &.collapser {
              display: flex;
              justify-content: flex-end;
              margin-top: 12px;
            }
          }
        }
      }
    }
    .right {
      svg {
        padding-left: 5px;
        max-width: 100%;
        height: 100%;
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        background-color: #f0f0f0;
        .path-line{
          height: 70%;
        }
      }
    }
  }
  