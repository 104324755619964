.navigation-bar {
  background-color: black;
  color: white;
  padding-top: 30px;
  padding-bottom: 30px;
  position: sticky;
  top: 0;
  z-index: 3;
  &.no-nav {
    visibility: hidden;
  }
  .brand-identity {
    position: relative;
    z-index: 1;
    a {
      svg {
        height: 27px;
        padding-right: 5px;
      }
    }
  }
  .page-list {
    &.desktop {
      ul {
        display: flex;
        justify-content: flex-end;
        li {
          padding-right: 60px;
          &:last-of-type {
            padding-right: 0;
          }
          a {
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.4px;
            &:hover {
              color: $primary_color;
            }
            &.active-link {
              color: $primary_color;
              font-weight: 600;
            }
          }
        }
      }
    }
    &.mobile {
      .menu-opener {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        a {
          height: 30px;
          width: 20px;
          display: flex;
          justify-content: space-between;
          .bars {
            height: 30px;
            background-color: white;
            border-radius: 50px;
            border: 0.5px solid white;
            &.bar-1 {
              height: 20px;
              transition: 0.4s;
            }
            &.bar-3 {
              height: 20px;
              display: flex;
              align-self: flex-end;
              transition: 0.4s;
            }
          }
          // svg {
          //   height: 30px;
          //   width: 30px;
          //   g {
          //     path {
          //       &#bar-1 {
          //         rotate: 0deg;
          //         transition: 0.2s;
          //       }
          //       &#bar-2 {
          //         opacity: 1;
          //         transition: 0.2s;
          //       }
          //       &#bar-3 {
          //         rotate: 0deg;
          //         transition: 0.2s;
          //       }
          //     }
          //   }
          // }
          &.active {
            // svg {
            //   g {
            //     path {
            //       &#bar-1 {
            //         rotate: 45deg;
            //         transition: 0.2s;
            //       }
            //       &#bar-2 {
            //         opacity: 0;
            //         transition: 0.2s;
            //       }
            //       &#bar-3 {
            //         rotate: -45deg;
            //         transition: 0.2s;
            //       }
            //     }
            //   }
            // }
            .bars {
              &.bar-1 {
                height: 29px;
                transform: translate(6px) rotate(45deg);
                transition: 0.4s;
              }
              &.bar-2 {
                display: none;
              }
              &.bar-3 {
                height: 29px;
                transform: translate(-11px, 1px) rotate(-45deg);
                transition: 0.4s;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-menu-container {
  height: 96vh;
  background-color: black;
  position: fixed;
  width: 100%;
  top: 90px;
  padding-top: 90px;
  z-index: 5;
  ul {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    li {
      position: relative;
      padding-bottom: 30px;
      &:last-of-type {
        padding-bottom: 300px;
      }
      &::before {
        content: "";
        border-top: 2px solid white;
        top: 0;
        position: absolute;
        width: 80%;
        margin-top: -10px;
      }
      a {
        font-size: 26px;
        line-height: 31px;
        font-weight: 700;
        padding-left: 30px;
      }
    }
  }
}
