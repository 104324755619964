.tabs {
  color: white;
  background-color: black;
  padding-bottom: 150px;
  padding-top: 80px;
  @media (max-width: 767px) {
    padding-bottom: 85px;
  }
  .home-navigator {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5px;
    padding-top: 5px;
    a {
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
    svg {
      fill: white;
      height: 25px;
      padding-left: 10px;
    }
  }
  .tab-item {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    &::before {
      content: "";
      border: 1px solid #8d8d8d;
      top: 0;
      position: absolute;
      left: 0;
      right: 0;
      background-color: #8d8d8d;
    }
    &:nth-child(4) {
      &::after {
        content: "";
        border: 1px solid #8d8d8d;
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        background-color: #8d8d8d;
      }
    }
    .tab-activator {
      span {
        font-size: 30px;
        font-weight: 200;
        @media (max-width: 1279px) {
          font-size: 24px;
        }
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      a {
        font-size: 30px;
        font-weight: 700;
        @media (max-width: 1279px) {
          font-size: 24px;
        }
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      .pinch-out-icon {
        position: relative;
        svg {
          height: 20px;
          fill: white;
          position: absolute;
          right: 0;
          &.angle-down {
            bottom: 0;
            @media (max-width: 1279px) {
              bottom: -3px;
            }
            @media (max-width: 767px) {
              bottom: -5px;
            }
          }
          &.angle-up {
            top: 0;
            @media (max-width: 1279px) {
              top: -3px;
            }
            @media (max-width: 767px) {
              top: -5px;
            }
          }
        }
      }
    }
    &.active {
      padding-bottom: 0px;
      .tab-activator {
        align-items: flex-start;
        span {
          padding-top: 5px;
        }
        a {
          font-size: 60px;
          transition: 0.5s;
          @media (max-width: 1279px) {
            font-size: 55px;
          }
          @media (max-width: 767px) {
            font-size: 48px;
          }
        }
        .pinch-out-icon {
          display: none;
        }
      }
      .tab-content {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        float: right;
        .text {
          margin-bottom: 20px;
          @media (max-width: 1279px) {
            padding-left: 80px;
          }
          @media (max-width: 767px) {
            padding-left: 35px;
          }
          @media (max-width: 424px) {
            padding-left: 32px;
          }
          @media (max-width: 374px) {
            padding-left: 28px;
          }
          p {
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.4px;
            line-height: 22px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .svg {
          display: flex;
          justify-content: flex-end;
          margin-top: -80px;
          img {
            max-width: 100%;
            width: 100%;
            @media (max-width: 1025px) {
              margin-top: 80px;
            }
          }
        }

        @media (max-width: 1279px) {
          .svg {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            img {
              max-width: 100%;
              width: 100%;
              @media (max-width: 1025px) {
                margin-top: 80px;
              }
            }
          }
        }
      }
      .tab-content-left {
        margin-top: 15px;
        // margin-top: -40px;
        // display: flex;
        // justify-content: space-between;
        // float: left;
        margin-left: 70px;
        padding-top: 6px;
        // margin-bottom: 50px;
        width: 70%;

        @media (max-width: 1800px) {
          transform: translateY(-130px)!important;
        }
        @media (max-width: 1400px) {
          margin-left: 55px;
        }
        @media (max-width: 1279px) {
          margin-left: 0px;
          margin-top: 0;
          margin-bottom: -50px;
          
        }
        @media (max-width: 767px) {
          // margin-left: 35px;
          width: 100%;
          margin-top: 0;
          margin-bottom: -50px;
        }
        @media (max-width: 500px) {
          // margin-left: 20px;
          width: 100%;
          margin-left: 0;
          margin-top: 0;
          margin-bottom: -50px;

        }
        // @media (max-width: 374px) {
        //   // margin: auto;
        //   width: 100%;
        //   margin-left: 0;
        //   margin-top: 0;
        //   margin-bottom: -50px;
        // }
      }


    }
  }
}

// .tabs-container {
//   @media (max-width: 500px) {
//     display: grid;
//     grid-template-rows: repeat(3, 1fr);
//   }
  
// }

// .tabs-container .content {
//   @media (max-width: 500px) {
//     display: grid;
//     grid-column: 1;
//     grid-row: 1 / span 3;
//     grid-template-rows: repeat(3, 1fr); /* Fallback */
//     grid-template-rows: subgrid; /* Limited support: https://caniuse.com/css-subgrid */
//     grid-template-columns: subgrid;
//   }

  
// }
// .tabs-container .tabs-child-content {
//   @media (max-width: 500px) {
//     grid-column: 1;
//     grid-row: 1;
//   }
  
// }
// .tabs-container .tabs-child-content-text {
//   @media (max-width: 500px) {
//     grid-column: 1;
//     grid-row: 3;
//   }
  
// }
// .tabs-container .tab-content-image {
//   @media (max-width: 500px) {
//     grid-column: 1;
//     grid-row: 2;
//   }
  
// }


