.testimonial-single{
    color: white;
    .prefix{
        margin-bottom: 10px;
        svg{
            height: 40px;
            max-width: 100%;
            position: relative;
            z-index: 1;
        }
    }
    .content{
        margin-bottom: 29px;
        p{
            font-size: 30px;
            font-style: italic;
            font-weight: 700;
            line-height: 30px;
            @media only screen and (max-width: 767px) {
                font-size: 24px;
            }
        }
    }
    .details{
        p{
            font-size: 24px;
            line-height: 28px;
            color: $primary_color;
            &.name{
                font-weight: 700;
            }
            &.from{
                font-weight: 200;
            }
        }
    }
}