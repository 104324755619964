.contact-us-page {
  background-color: black;
  color: white;
  padding-top: 75px;
  padding-bottom: 65px;
  display: flex;
  justify-content: space-between;  
  .page-title{
    h1{
      @media only screen and (max-width: 1099px) {
        font-size: 45px;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 28px;
      }
    }
  }
  .contact-form-container {
    display: flex;
    gap: 35px;
    flex-flow: initial;
    @media only screen and (max-width: 767px) {
      flex-flow: row wrap;
    }
    @media only screen and (max-width: 980px) {
      margin-top: 50px;
    }
    &>div {
      width: 100%;
    }

    .error {
      color: #ed4d41;
      float: right;
      margin-top: 8px;
      
    }
    label.error{
      margin-top: 0;
    }
    fieldset {
      border: 0px;
      padding: 0;
      padding-bottom: 32px;
      &.captchaVerify{
        padding-bottom: 0;
      }
      &.inputMessage{
        @media only screen and (max-width: 767px) {
          padding-bottom: 0;
        }
      }
      label {
        width: 100%;

        span {
          color: #fa7273;
          font-size: 16px;
          padding-left: 5px;
        }
      }

      .selectInterestField {
        margin-top: 15px;
        height: 51px;
        width: 100%;

        &>div {
          height: 100%;
          width: 100%;
          border-radius: 0px;
          background: transparent;
          color: white;
        }

        &:active {
          outline: 0px;
        }
      }

      &>input {
        margin-top: 15px;
        height: 51px;
        width: 100%;
        box-sizing: border-box;
        background: transparent;
        color: white;
        padding-left: 10px;
        border: 0.5px solid white;

        &:focus {
          outline: 0;
        }

        &::placeholder {
          color: #fff;
          opacity: 60%;
        }

        &#agreeTerms {
          height: 20px;
          width: 20px;
          margin-top: 0;
          margin-right: 10px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-color: transparent;
          border-radius: 50%;
          border: 2px solid #ccc;
          background-clip: content-box;
          padding: 4px;
          &:checked{
            background-color: #fa7273;
            border: 1px solid #fa7273;
           
          }
        }

        &.active-field {
          border: 2px solid #FF7C7C;
          box-shadow: 0px 0px 2px #FA7273;
        }

        &.error-field {
          border: 1px solid #C92D2D;
        }
      }

      & textarea {
        width: 100%;
        box-sizing: border-box;
        height: 175px;
        resize: none;
        margin-top: 15px;
        background: transparent;
        color: white;
        border: 0.5px solid white;
        padding: 10px;
        padding-top: 15px;

        &:focus {
          outline: 0;
        }

        &.active-field {
          border: 2px solid #FF7C7C;
          box-shadow: 0px 0px 2px #FA7273;
        }

        &.error-field {
          border: 1px solid #C92D2D;
        }
      }

      &.selectTerms {
        margin-top: 70px;
        display: flex;
        align-items: center;
        
      }
    }
    .submitForm{
      background: #FA7273;
      border: 0;
      outline: 0;
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 0;
      button{
        background: transparent;
        padding: 12px 7.5px 12px 19px;
        border: 0;
        outline: 0;
        width: 100%;
        text-align: left;
        color: white;
        cursor: pointer;
        &:focus{
          outline: 0;
        }
        z-index: 1;
      }
      svg{
        position: absolute;
        right: 7.5px;
        height: 24px;
        width: 24px;
        transition: all 0.1s ease-in-out;
      }
      &:hover{
        background-color: #FFAFAF;
        svg{
          scale: 1.3;
        }
      }

    }
    .loader{
      width: 48px;
      height: 48px;
      border: 5px solid;
      border-color: #FF7C7C transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    } 
    .submissionError{
      font-size: 15px;
      font-family: 'Articulat CF';
      font-weight: 400px;
      color: #ED4D41;
      line-height: 15.3px;
      margin-top: 15px;
    }
  }
}

