.homepage-highlighter {
    background-color: black;
    color: white;
    position: relative;
    overflow: hidden;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      padding-left: 0px;
    }
    .left {
      padding-top: 160px;
      padding-bottom: 100px;
      @media only screen and (max-width: 1279px) {
        max-width: 35%;
      }
      @media only screen and (max-width: 1023px) {
        max-width: unset;
      }
      @media only screen and (max-width: 767px) {
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 96px;
        padding-top: 140px;
      }
      .details {
        p {
          font-size: 24px;
          font-weight: 300;
          margin-bottom: 15px;
          line-height: 30px;
          &:last-of-type {
            margin-bottom: 0;
          }
          span {
            color: $primary_color;
            font-weight: 700;
          }
        }
        .additional{
          margin-top: 80px;
          p{

          }
        }
      }
    }
    .right {
    }
  }