.teamMobile{
  display: flex;
  flex-direction: column-reverse;
}
.teamTextMobile{
  display: flex;
  flex-direction: column;
}
.team-page {
  background-color: black;
  color: white;
  padding-bottom: 25px;
  // margin-bottom: 200px;
  position: relative;
  padding-top: 75px;
  overflow: hidden;
  .background{
    img{
      &.image-first{
        position: absolute;
        height: 357px;
        width: 395px;
        object-fit: cover;
        right: 15%;
        top: 20%;
        filter: blur(0) opacity(.4);
        @media only screen and (max-width: 1280px) {
          right: 10%;
        }
        @media only screen and (max-width: 1024px) {
          bottom: 10%;
          top: unset;
          right: 50%;
        }
        @media only screen and (max-width: 767px) {
          bottom: 10%;
          top: unset;
        }
      }
      &.image-second{
        position: absolute;
        height: 290px;
        width: 375px;
        object-fit: cover;
        left: 25%;
        bottom: 10%;
        filter: blur(0) opacity(.4);
        @media only screen and (max-width: 1280px) {
          right: 50%;
          bottom: -10%;
          left: unset;
        }
        @media only screen and (max-width: 1024px) {
          bottom: 35%;
          top: unset;
          right: -10%;
          left: unset;
        }
      }
      &.image-third{
        position: absolute;
        height: 155px;
        width: 365px;
        object-fit: cover;
        left: 65%;
        bottom: 10%;
        filter: blur(1px) opacity(.3);
        @media only screen and (max-width: 1280px) {
          bottom: -10%;
        }
        @media only screen and (max-width: 1024px) {
          bottom: 0%;
        }
      }
    }
  }
  .title-row {
    margin-bottom: 64px;
    h1 {
      span {
        color: $primary_color;
        &::-moz-selection {
            color: black;
            background: $primary_color;
        }
        
        &::selection {
          color: black;
          background: $primary_color;
        }
      }
    }
  }
  .content-row {
    p {
      font-size: 24px;
      font-weight: 200;
      letter-spacing: 0.6px;
      line-height: 32px;
      @media only screen and (max-width: 1024px) {
        font-size: 20px;
        line-height: 28px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
  .title {
    margin-bottom: 35px;
    max-width: fit-content;
    position: relative;
    margin-top: 40px;
    &::before {
      content: "";
      border: 2px solid white;
      position: absolute;
      width: calc(100% + 27px);
      left: -30px;
      margin-top: -12px;
    }
    h3{
      @media only screen and (max-width: 1024px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 24px;
        margin-top: 10px;
      }
    }
  }
  .team-list {
    margin-top: 10px;
    // padding-top: 12px;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      margin-bottom: 0;
      margin-top: -40px;

    }

    .left {
      .title {
        margin-bottom: 35px;
        max-width: fit-content;
        position: relative;
        margin-top: 10px;
        &::before {
          content: "";
          border: 2px solid white;
          position: absolute;
          width: calc(100% + 27px);
          left: -30px;
          margin-top: -12px;
        }
        h3{
          @media only screen and (max-width: 1024px) {
            font-size: 30px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 24px;
            margin-top: 10px;
          }
        }
      }
      .content {
        p {
          font-size: 16px;
          margin-bottom: 10px;
          line-height: 24px;
          font-weight: 300;
          letter-spacing: 0.6px;
          z-index: 1;
          &:last-of-type{
            margin-bottom: 0;
          }
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
            
          }
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
    }
    .right {
      @media only screen and (max-width: 1024px) {
        padding-top: 15px;
      }
      @media only screen and (max-width: 1544px) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .founder-team {
        justify-content: space-around;
      }
    }
  }
}
.team-page-request-demo{
  margin-left: 32px;
  width: 62%;
  margin-top: -50px;

  @media only screen and (max-width: 1919px) {
    margin-top: 70px;
    
  }

  @media only screen and (max-width: 767px) {
    margin-left: 15px;
    width: auto;
    margin-right: 15px;
  }

  @media only screen and (max-width: 500px) {
    margin-left: 0;
    padding-left: 15px;
    // padding-right: 15px;
    width: auto;
  }
}