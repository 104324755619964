.default-page {
  background-color: black;
  color: white;

  justify-content: space-between;
  .left {
    padding-top: 75px;
    padding-bottom: 150px;
    .main-content {
      margin-bottom: 140px;
      h1 {
        margin-bottom: 60px;

      }
      p {
        margin-bottom: 12px;
        font-size: 20px;
        line-height: 26px;
        @media only screen and (max-width: 1099px) {
          font-size: 18px;
          line-height: 25px;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .additional-content {
      .item {
        margin-bottom: 30px;
        &:first-of-type {

        }
      }
      h3 {
        margin-bottom: 8px;
        font-size: 20px;
      }
      p {
        padding-bottom: 10px;
        line-height: 23px;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .right {
    // img {
    //   max-width: 100%;
    //   right: 30px;
    //   position: fixed;
    //   height: 100%;
    // }
  }
}
