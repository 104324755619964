@import './styles/var';
@import url('https://use.typekit.net/gup1dsu.css');
@import './styles/_mixin';

* {
  padding: 0;
  margin: 0;
}

::-moz-selection {
  color: white;
  background: $primary_color;
}

::selection {
  color: white;
  background: $primary_color;
}

body {
  font-family: $font-family;
  background-color: black;
  // @media only screen and (min-width: 991px) {
  //   overflow: hidden;
  // }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

text {
  font-family: $font-family;
}

.logo-brand {
  text {
    font-weight: 700;
  }
}

.container {
  // Align Padding with Tailwind multiples
  padding-left: 32px;
  padding-right: 32px;

  @media only screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.container-home {
    padding-right: 0;
  }
}

strong {
  font-weight: 700;
}

h1 {
  font-size: 55px;
  font-weight: 700;
  color: white;
  line-height: 39px;

  @media only screen and (max-width: 1024px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 28px;
  }
}

h1 {
  font-size: 55px;
  font-weight: 700;
  color: white;
  line-height: 39px;

  @media only screen and (max-width: 1024px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 28px;
  }
}

h2 {
  font-size: 36px;
  font-weight: 700;
  color: white;
  line-height: 44px;

  @media only screen and (max-width: 1024px) {
    font-size: 27px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 24px;
  }
}

h3 {
  font-size: 30px;
  font-weight: 700;
}

p {
}

a,
button {
  font-size: 16px;
  font-weight: 700;
  color: white;
  text-decoration: none;
}

li {
  list-style: none;
}

//custom widths

.no-scroll {
  overflow: auto;
}

@import './styles/Home';
@import './styles/Contact';
@import './styles/Footer';
@import './styles/ThankYou';
@import './styles/Team';
@import './styles/Showcase';
@import './styles/Header';
@import './styles/MemberCard';
@import './styles/About';
@import './styles/Testimonial';
@import './styles/Tabs';
@import './styles/404';
@import './styles/Default';
@import './styles/Cookie';
@import './styles/Preloader';
@import './styles/PageLinks';
@import './styles/RequestDemoButton';
