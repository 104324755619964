.about-us-page {
  background-color: black;
  padding-top: 75px;
  overflow: hidden;
  .main-section {
    position: relative;
    .page-title {
      h1 {
        padding-bottom: 65px;
      }
    }
    .page-content {
      justify-content: space-between;
      padding-bottom: 100px;
      .left {
        .page-caption {
          position: relative;
          p {
            color: white;
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 12px;
            font-weight: 300px;
            letter-spacing: 0.4px;
            &:last-of-type {
              margin-bottom: 0;
            }
            a {
              text-decoration: underline;
              &:hover {
                color: $primary_color;
              }
            }
          }
        }
      }
      .right {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .word-map-back {
          position: absolute;
          top: 0;
          width: 100%;
          margin-top: -85px;
          scale: 1;
          @media only screen and (max-width: 1280px) {
            scale: 1;
            position: relative;
            right: 0;
            width: 100%;
            margin: auto;
          }
          @media only screen and (max-width: 1600px) {
            scale: 1;
            position: relative;
            right: 0;
            width: 100%;
            margin-top: 20px;
          }
        }
        .sub-header {
          z-index: 1;
          margin-right: 20%;
          @media only screen and (max-width: 1280px) {
            margin-top: 84px;
            margin-bottom: 35px;
          }
          @media only screen and (max-width: 767px) {
            margin-top: 109px;
          }
          h2 {
            @media only screen and (max-width: 1280px) {
              float: right;
            }
            @media only screen and (max-width: 1023px) {
              float: left;
            }
          }
        }
      }
    }
  }
}

.about-us-page-request-demo {
  width: 220%;
  @media only screen and (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
}
