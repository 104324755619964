.single-member {
  position: relative;
  .image{
    overflow: hidden;
    img {
      max-width: 100%;
      filter: grayscale(1);
      transform: scale(1.1);
    }
  }
  .details {
    // position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px 16px;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    .mains {
      h4 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        padding-bottom: 3px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .socials {
      a {
        color: white;
        float: right;
        svg {
          height: 24px;
          fill: white;
        }
        &:hover {
          svg {
            fill: $primary_color;
          }
        }
      }
    }
  }
}
