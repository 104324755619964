.request-demo-page {
    // background-color: yellow;
    @media only screen and (max-width: 767px) {
        width: 100%; //346px;
        margin-left: 0px;
    }
    .button-parent {
        // background-color: greenyellow;
        // .button-container{
        //     background: none;
        //     border: none;
        //     color: white;
        // }

        @media only screen and (max-width: 767px) {
            width: 100%; //346px;
        }

        background: #40E5AA;
        width: 22.7%;
        border: 0;
        outline: 0;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        padding-bottom: 0;
        margin-top: 50px;
        min-width: 200px;
        button{
            background: transparent;
            padding: 12px 7.5px 12px 19px;
            border: 0;
            outline: 0;
            width: 100%;
            text-align: left;
            color: black;
            cursor: pointer;
            &:focus{
            outline: 0;
            }
            z-index: 1;
        }
        svg{
            position: absolute;
            right: 7.5px;
            height: 24px;
            width: 24px;
            transition: all 0.1s ease-in-out;
        }
        &:hover{
            // background-color: #FFAFAF;
            svg{
            scale: 1.3;
            }
        }

        @media only screen and (max-width: 80em) {
            width: 346px; //346px;
        }

        @media only screen and (max-width: 767px) {
            // margin-left: 15px;
            width: 100%;
        }

        @media only screen and (max-width: 450px) {
            margin-left: 0px;
            width: 100%;
        }

    }
    
}