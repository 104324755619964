.error-page {
  background-color: black;
  height: 80vh;
  color: white;
  @media only screen and (max-width: 767px) {
    height: 88vh;
  }
  p {
    font-size: 30px;
    margin-top: 20px;
    @media only screen and (max-width: 1024px) {
      font-size: 24px;
      margin-top: 5px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  .text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 767px) {
      padding-top: 40px;
    }
  }
  .img {
    img {
      max-width: 100%;
      height: 80vh;
      float: right;
      @media only screen and (max-width: 991px) {
        margin-top: 20px;
        height: auto;
      }
    }
  }
}
