@mixin button($type) {
  @if ($type == "Primary") {
    background: #fa7273;
    border: 2px solid #fa7273;
  } @else {
    background: transparent;
    border: 2px solid white;
  }
  outline: 0;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 0;
  button {
    background: transparent;
    padding: 12px 89px 12px 14px;
    border: 0;
    outline: 0;
    width: 100%;
    text-align: left;
    color: white;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
    z-index: 1;
  }
  svg {
    position: absolute;
    right: 7.5px;
    height: 24px;
    width: 24px;
    transition: all 0.1s ease-in-out;
    fill: white;
  }
  &:hover {
    @if ($type == "Primary") {
      background-color: #ffafaf;
      border: 2px solid #ffafaf;
    } @else {
      background: #ffffffa1;
      border: 2px solid #ffffffa1;
    }
    svg {
      scale: 1.3;
    }
  }
}

@each $name, $width in $breakpoints {
  @for $i from 1 through 10 {
    $value: $i + 40;
    .mt-#{$i + 1} {
      margin-top: #{$value}px;
    }
    .mt-#{$name}-#{$i + 1} {
      @media only screen and (max-width: $width) {
        margin-top: #{$value}px;
      }
    }
  }
}