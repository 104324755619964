.showcase-list {
  padding-block: 30px;
  position: relative;
  min-height: 150px;
  display: flex;
  align-items: center;
  overflow: hidden;
  &::after {
    content: "";
    border: 4px solid white;
    position: absolute;
    left: 0;
    width: 36px;
    top: 15px;
  }
  .title-container {
    position: absolute;
    rotate: 270deg;
    top: 110px;
    h4 {
      color: white;
      font-size: 18px;
      line-height: 25px;
      font-weight: 900;
      text-transform: capitalize;
      left: -38px;
      position: absolute;
      margin-top: -3px;
      min-width: 100px;
      text-align: right;
    }
  }
  .logo-list {
    padding-left: 100px;
    @media (max-width: 900px) {
      padding-left: 50px;
    }
    padding-right: 30px;
    width: 100%;

    @media (max-width: 900px) {
      padding-right: 0px;
    }

    ul {
      display: flex;
      justify-content: flex-start;
      gap: 50px;

      @media (max-width: 900px) {
        justify-content: center;
      }

      li {
        padding-right: 35px;
        display: flex;
        align-items: center;
        a {
          img {
            max-height: 90px;
            object-fit: contain;
            width:170px;
          }
        }
      }
    }
  }
}
