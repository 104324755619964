.page-links {
  background-color: black;
  color: white;

  /* Add this CSS to style the dropdown menu */
  .dropdown-menu {
    display: none !important;
    position: absolute;
    height: fit-content;
    // top: 10%;
    color: white;
    z-index: 1;
  }

  // .dropdown-menu.open {
  //     transform: translateY(-100%); /* Shift the menu upwards by its own height */
  // }

  .dropdown-menu li {
    font-family: "Articulat CF";
    font-size: 14px;
    font-weight: 400;
    display: flex; /* Use flexbox to horizontally center */
    justify-content: center; /* Center content horizontally */
    align-items: center;
  }

  /* Show the dropdown menu when the 'About Us' link is hovered */

  .aboutus-li {
    overflow: hidden; /* Hide the content that overflows */
    // height: 40px; /* Initial height */
  }

  .aboutus-li.active {
    // height: 110px;
    // width: 66;
    margin-bottom: 0;
  }

  .footer-dropdown {
    display: none !important; /* Hide the dropdown menu by default */
    // margin: auto;
    color: white;
    z-index: 1;
    // position: relative !important;
    width: fit-content;
    top: 100%;
    transform: translateY(0);
    transition: transform 0.3s ease;
  }

  .footer-dropdown li {
    font-family: "Articulat CF";
    font-size: 14px;
    font-weight: 400;
    display: flex; /* Use flexbox to horizontally center */
    justify-content: center; /* Center content horizontally */
    align-items: center;
  }

  .dropdown-li {
    display: flex;
    align-items: center;
    padding: 0 !important;
    transition: height 0.3s ease;
  }

  .dropdown-li-mobile {
    display: flex;
    align-items: center;
    padding: 3px !important;
    transition: height 0.3s ease;
  }

  #about-us:hover + .dropdown-menu {
    display: block !important;
  }

  #about-us:hover + .footer-dropdown {
    display: block !important;
    transform: translateY(0);
    transition: transform 0.3s ease;
  }

  /* Style the individual dropdown items (Team and About) */
  .dropdown-menu:hover {
    display: block !important;
  }

  .footer-dropdown:hover {
    display: block !important;
    transform: translateY(0); /* Initial position, no vertical shift */
    transition: transform 0.3s ease;
  }

  /* Style the links in the dropdown */
  .dropdown-menu a {
    text-decoration: none;
    color: white; /* Text color for dropdown items */
    display: block;
  }

  .footer-dropdown a {
    text-decoration: none;
    color: white; /* Text color for dropdown items */
    display: block;
    top: 100%;
    padding: 5px 12px;
    transform: translateY(0); /* Initial position, no vertical shift */
    transition: transform 0.3s ease;
  }

  /* Change the background color of the dropdown items when hovered */
  .dropdown-menu a:hover {
    color: "white" !important;
  }

  .reddish {
    color: #ff7c7c;
  }
  .normal {
    color: inherit;
  }
  #team {
    // display: block;
    font-family: "articulat-cf";

    padding: auto;
    // padding-left: 10px;
    color: white;
  }
  #about {
    // padding-left: 9px;
    padding: auto !important;
    color: white;
    font-family: "articulat-cf";
  }

  #careers {
    // padding-left: 9px;
    padding: auto !important;
    color: white;
    font-family: "articulat-cf";
  }
}
