$breakpoints: (
  sm: 567px,
  md: 767px,
  lg: 1022px,
  xl: 1279px,
  xxl: 1919px,
);


$primary_color: #fa7273;
$font-family: "articulat-cf", sans-serif;
