/* Wondering why this isn't a SCSS file and imported as part of App.scss? See 
this issue for an explanation on why @apply functionality doesn't work well with
the resolve-url-loader CSS processing of SCSS files:
https://github.com/tailwindlabs/tailwindcss/issues/8806 */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /* Custom CSS for Blog Post rendering from Rich Text Editor in Storyblok */
    .blog-post {
      @apply tw-text-white;
  
      h1, h2, h3, h4 {
        @apply tw-mt-6;
      }
  
      h1 {
        @apply tw-text-3xl tw-font-bold;
      }
  
      h2 {
        @apply tw-text-2xl tw-font-bold;
      }
  
      h3 {
        @apply tw-text-2xl tw-font-bold;
      }
  
      h4 {
        @apply tw-text-[20px];
      }
  
      p {
        @apply tw-my-2;
      }
  
      a {
        @apply tw-underline tw-font-normal;
      }
  
      img {
        @apply tw-my-2;
      }
  
      ul {
        @apply tw-pl-6;
  
        li {
          @apply tw-list-disc marker:tw-text-white;
        }
      }
  
      ol {
        @apply tw-pl-6;
  
        li {
          @apply tw-list-decimal;
  
          p {
            @apply tw-ml-1;
          }
        }
      }
    }
  }