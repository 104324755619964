.homepage-usages {
    background-color: black;
    color: white;
    justify-content: space-between;
    padding-left: 30px;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
     padding-left: 0;
    }
    .left {
      padding-top: 145px;
      padding-bottom: 80px;
      @media only screen and (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      .title-row {
        h3 {
          color: white;
        }
      }
      .cards {
        margin-top: 50px;
      }
    }
    .right {
      position: relative;
      .path {
        &.path-1 {
          width: 100%;
          right: 0;
        }
        img {
          position: absolute;
          top: 40%;
          right: 22%;
        }
      }
      .toll-booth {
        position: absolute;
        top: -5px;
        right: 0;
        left: 0;
        margin-left: 0px;
        img {
          width: 100%;
        }
      }
    }
  }
  

  .img-caption-single {
    border-bottom: 2px solid white;
    margin-right: 30px;
    justify-content: space-between;
  
    @media only screen and (max-width: 767px) {
      margin-right: 0;
    }
    &:nth-child(2n) {
      margin-top: 80px;
      @media only screen and (max-width: 1024px) {
        margin-right: 0px;
      }
      @media only screen and (max-width: 1279px) {
        margin-top: 40px;
      }
      @media only screen and (max-width: 768px) {
        margin-top: 40px;
      }
    }
    &:nth-child(3n) {
      @media only screen and (max-width: 1439px) {
        margin-top: 0px;
      }
      @media only screen and (max-width: 1279px) {
        margin-top: 40px;
      }
    }
    &:nth-child(4n) {
      @media only screen and (max-width: 1279px) {
        margin-top: 40px;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 40px;
      }
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
    img {
      width: 100%;
      margin-bottom: 11px;
      height: 186px;
      object-fit: cover;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.4px;
      line-height: 23px;
      padding-bottom: 11px;
    }
  }
  