.homepage-quick-navigator {
    background-color: black;
    color: white;
    justify-content: space-between;
    padding-left: 30px;
    @media only screen and (max-width: 767px) {
      padding-left: 0px;
    }
    .left {
      padding-top: 80px;
      padding-bottom: 150px;
      padding-left: 0px;
      @media only screen and (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      .title-row {
        margin-bottom: 67px;
        h4 {
          font-size: 24px;
          font-weight: 700;
          color: white;
          span {
            color: #ffffff;
            font-weight: 200;
          }
        }
      }
      .navigator-cards {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media only screen and (max-width: 1578px) {
          gap: 80px;
        }
        @media only screen and (max-width: 1190px) {
          gap: 0px;
        }
        .navigator-single {
          display: flex;
          justify-content: space-between;
          position: relative;
          padding-top: 15px;
          margin-right: 120px;
          @media only screen and (max-width: 1578px) {
            margin-right: 0px;
          }
          &:last-of-type {
            margin-right: 0;
          }
          @media only screen and (max-width: 767px) {
            margin-bottom: 60px;
          }
          &:hover {
            svg {
              rect {
                fill: $primary_color;
              }
            }
          }
          &::before {
            content: "";
            border: 2px solid white;
            left: 0;
            right: 0;
            top: 0;
            position: absolute;
          }
          h3 {
            line-height: 36px;
            span {
              color: $primary_color;
            }
          }
          svg {
            height: 55px;
            width: 55px;
          }
        }
      }
    }
    .right {
      .path {
        &.path-1 {
          width: 100%;
          right: 0;
        }
        img {
          position: absolute;
          top: 40%;
          right: 25%;
        }
      }
    }
  }
  