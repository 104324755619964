.thank-you-page {
  background-color: black;
  color: white;
  padding-top: 76px;
  padding-bottom: 145px;
  .page-title {
    margin-bottom: 40px;
    h1{
      @media only screen and (max-width: 767px) {
        width: 72% !important;
        font-size: 40px;
      }
    }
  }
  .thank-you-message-container {
    p{
        font-size: 30px;
        line-height: 36px;
        font-weight: 600;
        width: 80%;
        @media only screen and (max-width: 767px) {
          width: 100% ;
        }
    }
  }
  .button-container {
    .buttonNavigator {
      background: $primary_color;
      border: 0;
      outline: 0;
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0;
      margin-top: 76px;
      a {
        background: transparent;
        padding: 12px 7.5px 12px 19px;
        border: 0;
        outline: 0;
        width: 100%;
        text-align: left;
        color: white;
        cursor: pointer;
        text-decoration: none;
        &:focus {
          outline: 0;
        }
        z-index: 1;
      }
      svg {
        position: absolute;
        right: 7.5px;
        height: 24px;
        width: 24px;
        transition: all 0.1s ease-in-out;
      }
      &:hover {
        background-color: #ffafaf;
        svg {
          scale: 1.3;
        }
      }
    }
  }
}
