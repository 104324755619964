@keyframes fadeOutLoader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pre-loader-container {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 9;
  background: black;
  right: 0;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &.loaded {
    animation-name: fadeOutLoader;
    animation-duration: 0.5s;
  }
}
