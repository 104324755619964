.homepage-tab-navigator {
    background-color: black;
    justify-content: space-between;
    color: white;
    position: relative;
    padding-right: 15px;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
      padding-left: 0;
    }
  
    & > .left {
      padding-right: 15px;
      overflow: hidden;
      @media only screen and (max-width: 767px) {
        padding-left: 15px;
      }
    }
  
    .title-row {
      padding-bottom: 25px;
      padding-top: 115px;
      h3 {
        font-weight: 200;
        font-size: 24px;
      }
    }
    .list-tabs {
      .tab-single {
        align-items: flex-start;
        display: flex;
        position: relative;
        overflow: hidden;
        z-index: 1;
        .active-tab-handle {
          content: "";
          position: absolute;
          top: -5px;
          bottom: 5px;
          left: 0px;
          right: 0px;
        }
        .active-arrow-svg {
          position: absolute;
          right: 0px;
          bottom: 5px;
          height: 30px;
        }
        &::before {
          content: "";
          border-top: 2px solid white;
          position: absolute;
          width: 100%;
          @media only screen and (max-width: 767px) {
            width: calc(100% + 30px);
          }
          // left: -30px;
          margin-top: 0px;
          z-index: 1;
        }
        .left {
          position: relative;
          z-index: 1;
          margin-top: 22px;
          span {
            font-size: 30px;
            line-height: unset;
            font-weight: 200;
          }
        }
        .right {
          position: relative;
          z-index: 1;
          margin-top: 22px;
  
          h1 {
            font-size: 80px;
            line-height: 70px;
            padding-bottom: 40px;
            @media only screen and (max-width: 1024px) {
              padding-left: 20px;
            }
            @media only screen and (max-width: 1279px) {
              font-size: 60px;
              padding-bottom: 20px;
              line-height: 55px;
            }
            @media only screen and (max-width: 767px) {
              font-size: 36px;
              line-height: 36px;
            }
          }
          .excerpt-container {
            p {
              font-size: 18px;
              font-weight: 300;
              color: #ffffff;
              margin-bottom: 60px;
              line-height: 26px;
              letter-spacing: 0.3px;
              span {
                font-weight: 300;
              }
              @media only screen and (max-width: 1024px) {
                padding-left: 20px;
              }
            }
          }
        }
        .buttonNavigator {
          @include button("Primary");
          margin-bottom: 20px;
        }
      }
    }
    & > .right{
      @media only screen and (max-width: 767px) {
        margin-top: 50px;
      }
    }
  }