.cookie {
  background-color: #242424;
  color: white;
  padding-top: 30px;
  padding-bottom: 30px;
  z-index: 2;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  .text {
    p {
      line-height: 20px;
    }
  }
  .buttons {
    @media only screen and (max-width: 1023px) {
      margin-top: 35px;
    }
    .decline-cookie {
      float: right;

      padding: 0;
      @include button("Secondary");
      margin-right: 10px;
      @media only screen and (max-width: 767px) {
        float: left;
        margin-top: 10px;
        margin-right: 0;
      }
    }
    .accept-cookie {
      float: right;
      @media only screen and (max-width: 767px) {
        float: left;
      }
      padding: 0;
      @include button("Primary");
    }
  }
}
