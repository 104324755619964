.footer {
  background-color: black;
  color: white;
  padding-top: 50px;
  z-index: 1;
  position: relative;
  border-top: 1px solid rgb(36, 36, 36);
  .footer-text {
    color: #afb3b9;
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 15px;
  }
  .top-level {
    .quick-links {
      ul {
        li {
          padding-bottom: 10px;
          &:last-of-type {
            margin-bottom: 0;
          }
          a {
            font-weight: 400;
            &:hover {
              color: $primary_color;
            }
          }
        }
      }
    }
    .extra-links {
      .information-links {
        ul {
          li {
            a {
              font-weight: 400;
              line-height: 20px;
              text-decoration: underline;
              &:hover {
                color: $primary_color;
              }
            }
          }
        }
      }
      .social-links {
        margin-top: 70px;
        ul {
          display: flex;
          li {
            padding-right: 20px;
            &:last-of-type {
              padding-right: 0;
            }
            a {
              svg {
                height: 24px;
                fill: white;
                &:hover {
                  fill: $primary_color;
                }
              }
            }
          }
        }
      }
    }
  }
  .lowel-level {
    padding: 40px 0px 20px 0px;
    display: flex;
    justify-content: space-between;
    .version {
      color: black;
    }
    p {
      font-weight: 400;
      a {
        text-decoration: underline;
        font-weight: 400;
        color: rgb(139, 139, 139);
        &:hover {
          color: $primary_color;
        }
      }
    }
  }
}
