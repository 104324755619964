.homepage-hero-section {
  background-color: black;
  position: relative;
  justify-content: space-between;
  padding-right: 15px;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    padding-left: 0;
  }
  .gif {
    width: 100%;
  }
  .gif-container {
    margin-top: 130px;
    position: relative;
  }
  .highlight {
    padding-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
    @media only screen and (min-width: 768px) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 65px;
      min-height: calc(100vh - 495px);
    }
    h2 {
      font-size: 76px;
      font-weight: 200;
      line-height: 86px;
      @media (min-width: 1875px) and (max-width: 1910px) {
        width: 90%;
      }
      @media (min-width: 1590px) and (max-width: 1874px) {
        width: 100%;
      }
      @media (min-width: 1579px) and (max-width: 1590px) {
        width: 90%;
      }
      @media (min-width: 1508px) and (max-width: 1579px) {
        width: 75%;
      }
      @media only screen and (max-width: 1578px) {
        font-size: 60px;
        line-height: 66px;
      }
      @media only screen and (max-width: 1439px) {
        font-size: 40px;
        line-height: 50px;
        width: 55%;
      }
      @media only screen and (max-width: 1331px) {
        width: 60%;
      }
      @media only screen and (max-width: 1222px) {
        width: 65%;
      }
      @media only screen and (max-width: 1191px) {
        width: 80%;
      }
      @media only screen and (max-width: 1151px) {
        width: 90%;
      }
      @media only screen and (max-width: 324px) {
        font-size: 35px;
        line-height: 40px;
      }
      span {
        font-weight: 700;
      }
    }
  }
  .brand {
    margin-top: 285px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
    overflow: hidden;
    @media only screen and (min-width: 768px) {
      padding-left: 0px;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 144px;
    }
    .content {
      h1 {
        color: $primary_color;
        padding-bottom: 20px;
        font-weight: 700;
      }
      p {
        color: white;
        font-size: 24px;
        font-weight: 200;
        line-height: 30px;
        word-break: break-word;
        padding-right: 15px;
        span {
          font-weight: 700;
        }
      }
    }
  }
}

.heresection-request-demo {
  margin-top: -40px;
  @media only screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}
