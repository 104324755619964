.gif-container {
    &::before {
      content: " ";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      height: 40px;
      left: 0;
      right: 0;
      position: absolute;
      top: 0;
      z-index: 2;
    }
    &::after {
      content: " ";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      height: 40px;
      left: 0;
      right: 0;
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
  }